<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">
        {{ $t("labels.history") }}
        <v-spacer></v-spacer>
        <v-btn small color="red darken-1" text @click="cancel">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-simple-table
          fixed-header
          height="calc(100vh - 235px)"
          class="table-padding-2"
        >
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">Thời gian</th>
                <th role="columnheader" class="text-center">Nguồn</th>
                <th role="columnheader" class="text-center">Mã nguồn</th>
                <th role="columnheader" class="text-center">Chức năng</th>
                <th role="columnheader" class="text-center">Tài khoản</th>
                <th role="columnheader" class="text-center">Khả dụng</th>
                <th role="columnheader" class="text-center">System Response</th>
                <th role="columnheader" class="text-center">Trạng thái</th>
                <th role="columnheader" class="text-center">Phân loại</th>
                <th role="columnheader" class="text-center">Tracking</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-for="item in items" :key="item.id">
                <td>
                  {{ formatDateTime(item.created_at, "HH:mm:ss DD/MM/YYYY") }}
                </td>
                <td>{{ $t(`labels.uid_source_type_${item.source_type}`) }}</td>
                <td>{{ item.warehouse_code || item.pos_code }}</td>
                <td>{{ $t(`labels.uid_action_type_${item.action_type}`) }}</td>
                <td>{{ item.identity_name }}</td>
                <td>
                  <span :class="{ 'error--text': !item.available }">{{
                    item.available ? "Yes" : "No"
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'error--text': !item.system_status }">{{
                    item.system_status ? "Yes" : "No"
                  }}</span>
                </td>
                <td>{{ $t(`labels.uid_status_${item.status}`) }}</td>
                <td>{{ $t(`labels.uid_order_type_${item.order_type}`) }}</td>
                <td>
                  <a
                    v-if="item.tracking_id"
                    :href="trackingLink(item)"
                    target="_blank"
                    >{{ item.tracking_id }}</a
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "UidHistory",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    filters: {},
    items: [],
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    cancel() {
      this.$emit("cancel");
    },
    trackingLink(item) {
      if (
        item.goods_issue_detail_tracking_id ||
        item.goods_issue_detail_return_tracking_id
      ) {
        return `/goods-issue/detail?tracking_id=${item.tracking_id}`;
      } else if (
        item.pos_order_tracking_id ||
        item.pos_order_return_tracking_id
      ) {
        return `/pos-order/detail?tracking_id=${item.tracking_id}`;
      } else if (item.goods_receipt_tracking_id) {
        return `/goods-receipt/list?goods_receipt_tracking=${item.tracking_id}&sku=${this.item.sku}`;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/goods/v1/admin-uid-history", {
          id_goods_special_barcode: this.item.id,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data];
        });
    }, 500),
  },
};
</script>

<style scoped></style>
